<template>
  <div class="card-ocr">
    <van-action-sheet v-model="cardShow" title="车辆信息识别">
      <van-row class="card-side">
        <van-col span="12" v-if="!quote.vehicle.plateFlag" @click="type = 'vehiclelicense'">
          <van-uploader :after-read="ocrVehicle" style="text-align: center;">
            <template>
              <img
                v-if="cardImg['vehicle']['vehiclelicense']"
                width="70%"
                height="80"
                :src="cardImg['vehicle']['vehiclelicense']"
              />
              <img width="70%" src="./image/cartravel.png" v-else />
            </template>
          </van-uploader>
        </van-col>
        <van-col span="12" v-if="quote.vehicle.plateFlag" @click="type = 'vehicleInvoice'">
          <van-uploader :after-read="ocrVehicle" style="text-align: center;">
            <template>
              <img
                v-if="cardImg['vehicle']['vehicleInvoice']"
                width="70%"
                height="80"
                :src="cardImg['vehicle']['vehicleInvoice']"
              />
              <img width="70%" src="./image/invoice.png" v-else />
            </template>
          </van-uploader>
        </van-col>
        <van-col span="12" v-if="quote.vehicle.plateFlag" @click="type = 'vehicleCertificate'">
          <van-uploader :after-read="ocrVehicle" style="text-align: center;">
            <template>
              <img
                v-if="cardImg['vehicle']['vehicleCertificate']"
                width="70%"
                height="80"
                :src="cardImg['vehicle']['vehicleCertificate']"
              />
              <img width="70%" src="./image/qualified.png" v-else />
            </template>
          </van-uploader>
        </van-col>
      </van-row>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Notify, Toast } from "vant";
import { request } from "@/api/service";
import store from "@/store";
import ImagesQuicklyCompress from "images-quickly-compress";
export default {
  name: "CardOcr",
  mixins: [],
  props: {},
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {},
        };
      },
    },
  },
  data() {
    return {
      cardShow: false,
      type: null
    };
  },
  watch: {},
  computed: {
    ...mapState("car", ["quote","quoteRes","cardImg"]),
  },
  async created() {
  },
  mounted() {},
  methods: {
    ocrVehicle(fileObj) {
      this.handleFileOcr(fileObj, {
        type: this.type
      });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly(this.type, fileObj)
      return false;
    },
    async handleFileOcr(upLoadObj, reqParam) {
      let { file } = upLoadObj;
      let isLt5M = file.size / 1024 / 1024 < 5;
      const suffixMap = {
        "image/jpeg": ".jpeg",
        "image/jpg": ".jpg",
        "image/png": ".png",
      };
      if (!isLt5M) {
        if (
          !(
            suffixMap[file.type] &&
            [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
          )
        ) {
          Toast("只能上传5M以下的图片");
          return;
        }
        if (
          suffixMap[file.type] &&
          [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
        ) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: "pixel", // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: "500kb", // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false,
          }).compressor([file]);
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5;
          file = new window.File([compressFiles[0]], file.name, {
            type: compressFiles[0].type,
          });
        }
        if (!isLt5M) {
          Toast("请上传5M以下的图片");
          return;
        }
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", reqParam.type);
      // 发起请求
      request({
        url: "/afis-engine/ocr/recognition",
        method: "post",
        data: formData,
        headers: {
          "Access-Token": store.getters.info && store.getters.info.token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.setOcrIdCard(res, this.type);
          this.cardShow = false;
        })
        .catch((err) => {
          Toast(err.message || "上传失败");
        });
    },
    setOcrIdCard(res, type) {
      const form = this.pageContext.pageForm;
      const hasProp = (prop, formCode) =>
        form[formCode] && form[formCode].hasOwnProperty(prop)
      switch (type) {
        case 'vehiclelicense':
          const {
            brandModel,
            engineNo,
            frameNo,
            plateNo,
            registDate,
            owner,
            useProperty
          } = res
          if (hasProp('brandModel', 'vehicle')) {
            form['vehicle'].brandModel = brandModel
          }
          if (hasProp('engineNo', 'vehicle')) {
            form['vehicle'].engineNo = engineNo
          }
          if (hasProp('frameNo', 'vehicle')) {
            form['vehicle'].frameNo = frameNo
          }
          if (hasProp('plateNo', 'vehicle')) {
            form['vehicle'].plateNo = plateNo
          }
          if (hasProp('name', 'owner')) {
            form.owner.name = owner
          }
          if (hasProp('registerDate', 'vehicle')) {
            form['vehicle'].registerDate = new Date(registDate).getTime()
          }
          if (hasProp('vehicleUsage', 'vehicle')) {
            form['vehicle'].vehicleUsage =
              useProperty === '营运' ? '1' : '2'
          }
          break
        case 'vehicleInvoice':
          if (hasProp('name', 'owner')) {
            form.owner.name = res.purchaser
          }
          if (hasProp('certificateNo', 'owner')) {
            form.owner.certificateNo = res.purchaserCode
          }
          if (hasProp('engineNo', 'vehicle')) {
            form['vehicle'].engineNo = res.engineNum
          }
          if (hasProp('frameNo', 'vehicle')) {
            form['vehicle'].frameNo = res.vinNum
          }
          if (hasProp('brandModel', 'vehicle')) {
            form['vehicle'].brandModel = res.manuModel
          }
          break
        case 'vehicleCertificate':
          if (hasProp('issueCardDate', 'vehicle')) {
            form['vehicle'].issueCardDate = res.certificateDate
          }
          if (hasProp('frameNo', 'vehicle')) {
            form['vehicle'].frameNo = res.vinNo
          }
          if (hasProp('engineNo', 'vehicle')) {
            form['vehicle'].engineNo = res.engineNo
          }
          if (hasProp('fuelType', 'vehicle')) {
            form['vehicle'].fuelType = res.fuelType
          }
          if (hasProp('displacement', 'vehicle')) {
            form['vehicle'].displacement = res.displacement
          }
          if (hasProp('emptyWeight', 'vehicle')) {
            form['vehicle'].emptyWeight = res.saddleMass
          }
          if (hasProp('seatCount', 'vehicle')) {
            form['vehicle'].seatCount = res.limitPassenger
          }
          if (hasProp('brandModel', 'vehicle')) {
            form['vehicle'].brandModel = res.carBrand
          }
          break
      }

      this.$store.dispatch("car/setQuote", this.pageContext.pageForm);
    },
    async imagesQuickly (key, fileObj, type) {
      // 超过5m的图片文件, 启用压缩
      let { file } = fileObj;
      const compressFiles = await new ImagesQuicklyCompress({
        mode: "pixel", // 根据像素总大小压缩
        num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
        size: "500kb", // 图片大小超过500kb执行压缩
        imageType: file.type, // jpeg压缩效果十分理想
        quality: 0.8,
        orientation: false,
      }).compressor([file]);
      file = new window.File([compressFiles[0]], file.name, {
        type: compressFiles[0].type,
      });
      let _this = this
      var reader = new FileReader();
      reader.readAsDataURL(compressFiles[0]);
      reader.onload = function(){
        let cardImg = store.getters.cardImg
        cardImg['vehicle'][_this.type] = reader.result
        store.dispatch("car/setCardImg", cardImg)
      };
    }
  },
};
</script>
<style lang="scss" scoped>
.card-ocr ::v-deep {
  .card-side {
    margin-top: 20px;
    margin-bottom: 16px;
    .type-des {
      text-align: center;
      font-size: 12px;
      color: red;
      margin-top: 10px;
      margin-bottom: 6px;
      margin-left: -20px;
    }
  }
  .van-action-sheet__header {
    border-bottom: 1px solid #ccc;
    font-size: 15px;
  }
}
</style>