<template>
  <div class="vehicle-select">
    <van-popup v-model="show" round position="bottom" :style="{ width: '100%',maxHeight:'80%' }" >
      <div class="h-picker">
        <div class="h-picker_top">
          <div class="h-picker_content">
            <div class="h-picker_title">车型确认</div>
            <button type="button" class="h-picker_close" @click="close">X</button>
          </div>
        </div>
        <div class="h-picker_columns">
          <div>
            <van-radio-group v-model="insVehicleModelId">
              <van-cell-group>
                <van-cell v-for="(row,index) in resData.vehicleModels" :key="index" :title="handleCarInfo(row)">
                  <template #icon>
                    <van-radio :name="row.insVehicleModelId"  checked-color="#ed6a0c"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
        <div class="h-picker_bottom">
          <div class="h-picker_bnt">
            <van-button type="primary" block round @click="handleClick">确认并报价</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { keys,cloneDeep } from 'lodash'
import { Notify } from 'vant'
export default {
  name: 'VehicleSelect',
  inheritAttrs: false,
  components: {},
  props: {
  },
  data () {
    return {
      resData:{},
      insVehicleModelId:null,
      show:false,
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    open(data){
      this.show = true
      this.resData=data
      this.insVehicleModelId = null
    },
    close(){
      this.show = false
    },
    handleCarInfo(car){
      const handleUnit = {
        purchasePrice:'元',
        seatCount:'座',
        yearPattern:'款'
      }
      let obj = {
        brandModel: car.brandModel,
        configName: car.configName,
        familyName: car.familyName,
        brandName: car.brandName,
        yearPattern: car.yearPattern,
        gearboxType: car.gearboxType,
        seatCount: car.seatCount,
        displacement: car.displacement,
        purchasePrice: car.purchasePrice
      }
      return keys(obj)
        .filter(fk =>car[fk] && fk !== 'vehicleModelId' && fk !== 'brandModelCode')
        .map(fk => `${car[fk]}${handleUnit[fk]||''}`)
        .join('/')
    },
    handleClick(){
      if(!this.insVehicleModelId){
        Notify({ type: 'warning', message: '请先选择车型信息' });
        return false
      }
      const rows=this.resData.vehicleModels.filter(item=>item.insVehicleModelId===this.insVehicleModelId)
      if(rows.length < 1){
        Notify({ type: 'warning', message: '返回数据有误' });
        return false
      }
      const car = cloneDeep(rows[0])
      const form = {
        brandName : car.brandName,
        configName : car.configName,
        purchasePrice : car.purchasePrice?String(car.purchasePrice):null,
        insVehicleModelId:car.insVehicleModelId,
        yearPattern : car.yearPattern,
        brandModelCode : car.brandModelCode,
        seatCount : car.seatCount?String(car.seatCount):null,
        expandInformation : car.expandInformation,
        platModelCode : car.platModelCode
      }
      this.show = false
      this.$emit('selectVehicle',form)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.vehicle-select ::v-deep{
  .van-radio{
    padding-right: 10px;
  }
  div[aria-checked=true] + div{
    font-weight: $font-weight-bold-lg;
    color: $text-color;
  }
}

.vehicle-select{
  .h-picker{
    position: relative;
    background-color: $white;
    -webkit-user-select: none;
    user-select: none;
    &_top{
      position:fixed;
      left: 0;
      right: 0;
      width:100%;
      z-index: 90;
      border-radius: 16px 16px 0 0;
      background-color: $white;
    }
    &_content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding: 5px 20px;
        position: relative;
        &::after {
          @include hairline-bottom($border-color, $padding-sm, $padding-sm);
        }
      }
    &_title{
      max-width: 50%;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
    &_close{
      height: 100%;
      font-size: 14px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    &_columns{
      padding: 55px 0;
    }
    &_bottom{
        position:fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width:100%;
        z-index: 90;
        background-color: $white;
    }
    &_bnt{
      padding: 5px 20px;
      position: relative;
      &::after {
        @include hairline-top($border-color, $padding-sm, $padding-sm);
      }
    }
  }
}
</style>
