<template>
  <div class="vehicle-model">
    <van-dialog v-model="show" title="验证码确认" confirmButtonText="确认并报价" confirmButtonColor="#57ccc2" :before-close="confirm">
      <van-field
        v-model="codeVal"
        clearable
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <img :src="resData.vehicle.jgCheckCodeStr" style="width: 55px; height: 22px"/>
        </template>
      </van-field>
    </van-dialog>
  </div>
</template>
<script>
import Tesseract from 'tesseract.js'
import { Notify } from 'vant'
export default {
  name: 'CheckCode',
  inheritAttrs: false,
  components: {},
  props: {
  },
  data () {
    return {
      resData:{},
      codeVal:null,
      show:false,
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    open(data){
      this.resData=data
      this.show = true
      this.ocrCode()
    },
    confirm(action, done){
      if(!this.codeVal||this.codeVal.length!==4){
        Notify({ type: 'warning', message: '请输入4位验证码' });
        done(false)
        return false
      }
      done()
      this.$emit('checkCode',this.codeVal)
    },
    ocrCode(){
      Tesseract.recognize(this.resData.vehicle.jgCheckCodeStr, 'eng', {
        logger: m => {
        }
      }).then(({ data: { text } }) => {
          const val = text
            .replace(/[^a-zA-Z\d]/g, '')
            .replace(/\s+/g, '')
            .substring(0, 4)
          this.codeVal = val && val.length === 4 ? val : ''
        })
        .catch(e => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.vehicle-model{
  // height: 100vh;
  // overflow: hidden;
  &_search{
    // height: 50px;
  }
  &_list{
    // height: calc(100vh - 50px);
    // overflow: auto;
    ::v-deep .van-cell{
      padding:5px 15px;
    }
    ::v-deep .van-cell::after{
      display: flex;
    }
  }
}
.van-search__action{
  color:$primary-color
}
</style>
