var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quote-normal-base h-font-lg"},[_c('van-tabs',{ref:"vanTab",staticStyle:{"padding-bottom":"60px"},attrs:{"type":"card","sticky":"","scrollspy":"","ellipsis":false},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_vm._l((_vm.config.formLayout),function(formItem,index){return _c('van-tab',{key:index,attrs:{"name":formItem.fmCode,"title":formItem.fmName}},[_c('div',{staticClass:"quote-normal-base_content"},[_c('HYForm',{key:index,ref:formItem.fmCode + 'FormRef',refInFor:true,attrs:{"fmCode":formItem.fmCode,"config":formItem,"form":_vm.pageForm[formItem.fmCode]},scopedSlots:_vm._u([{key:"vehicle_plateNo",fn:function(ref){
var form = ref.form;
var config = ref.config;
return [_c('HInput',{attrs:{"config":config},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('div',{staticClass:"vehicle-plateNo h-flex"},[_c('van-checkbox',{attrs:{"iconSize":"16px","checkedColor":"#57ccc2","shape":"square"},model:{value:(form.plateFlag),callback:function ($$v) {_vm.$set(form, "plateFlag", $$v)},expression:"form.plateFlag"}},[_vm._v("无")]),_c('van-icon',{attrs:{"name":"scan","size":"20"},on:{"click":_vm.plateOcrCli}})],1)]},proxy:true}],null,true),model:{value:(form.plateNo),callback:function ($$v) {_vm.$set(form, "plateNo", $$v)},expression:"form.plateNo"}})]}},{key:"vehicle_modelDesc",fn:function(ref){
var config = ref.config;
return [_c('HField',{attrs:{"config":config},nativeOn:{"click":function($event){return _vm.openVehicleModel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('div',{staticClass:"vehicle-modelDesc"},[_c('i',{staticClass:"van-icon van-icon-arrow van-cell__right-icon",on:{"click":_vm.openVehicleModel}})])]},proxy:true}],null,true)})]}},{key:"holderHeader",fn:function(ref){
var form = ref.form;
return [_c('div',{staticClass:"form-header h-flex h-row-between"},[_c('van-checkbox',{attrs:{"shape":"square","checkedColor":"#57ccc2","iconSize":"16px"},model:{value:(form.sameOwnerFlag),callback:function ($$v) {_vm.$set(form, "sameOwnerFlag", $$v)},expression:"form.sameOwnerFlag"}},[_vm._v("同车主")]),_c('van-icon',{attrs:{"name":"scan","size":"20"},on:{"click":function($event){return _vm.cardOcrCli('holder')}}})],1)]}},{key:"insuredHeader",fn:function(ref){
var form = ref.form;
return [_c('div',{staticClass:"form-header h-flex h-row-between"},[_c('div',{staticClass:"h-flex h-row-between"},[_c('van-checkbox',{attrs:{"shape":"square","checkedColor":"#57ccc2","iconSize":"16px"},model:{value:(form.sameHolderFlag),callback:function ($$v) {_vm.$set(form, "sameHolderFlag", $$v)},expression:"form.sameHolderFlag"}},[_vm._v("同投保人")]),_vm._v("    "),_c('van-checkbox',{attrs:{"shape":"square","checkedColor":"#57ccc2","iconSize":"16px"},model:{value:(form.sameOwnerFlag),callback:function ($$v) {_vm.$set(form, "sameOwnerFlag", $$v)},expression:"form.sameOwnerFlag"}},[_vm._v("同车主")])],1),_c('van-icon',{attrs:{"name":"scan","size":"20"},on:{"click":function($event){return _vm.cardOcrCli('insured')}}})],1)]}}],null,true)})],1)])}),_c('van-tab',{key:"risk",attrs:{"name":"risk","title":"险种信息"}},[_c('Risk',{key:"risk",ref:"riskFormRef"})],1)],2),_c('VehicleModel',{ref:"vehicleModelRef",on:{"selectModel":_vm.selectModel}}),_c('div',{staticClass:"quote-normal-base_bnt"},[_c('van-row',{staticClass:"quote-normal-base_bnt--fixed",attrs:{"type":"flex","justify":"center"}},[_c('div',{staticClass:"warn"},[_c('i',{staticClass:"van-icon van-icon-warning"}),_vm._v(" 以上报价仅供参考，最终价格以出单当日为准 ")]),_c('div',{staticClass:"btn"},[_c('van-button',{attrs:{"loading":_vm.pageData.submitLoading,"loading-text":"报价中","type":"primary","block":"","round":""},on:{"click":_vm.submit}},[_vm._v("立即报价")])],1)])],1),_c('CardOcr',{ref:"holderOcr",attrs:{"type":'holder'}}),_c('CardOcr',{ref:"insuredOcr",attrs:{"type":'insured'}}),_c('PlateOcr',{ref:"plateOcr"}),_c('CheckCode',{ref:"checkCodeRef",on:{"checkCode":_vm.checkCode}}),_c('VehicleSelect',{ref:"vehicleSelectRef",on:{"selectVehicle":_vm.selectVehicle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }