<template>
  <div v-if="config.formLayout" class="quote-normal h-font-lg">
      <!--基本信息-->
      <div class="form quote-normal_basis">
        <div class="form-title">
          <van-row>
            <van-col span="24">
              <span class="icon" style="background-color:#07c160">基</span>
              <span>{{ config.formLayout['basis'].fmName }}</span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="basisFormRef"
            fmCode="basis"
            :config="config.formLayout['basis']"
            :form="pageForm['basis']"
          >
          </HYForm>
        </div>
      </div>
      <!--交强险-->
      <div class="form quote-normal_compulsory">
        <div class="form-title">
          <van-row>
            <van-col span="12">
              <span class="icon" style="background-color:#57ccc2">交</span>
              <span>{{ config.formLayout['compulsory'].fmName }}</span>
            </van-col>
            <van-col span="12">
              <span><van-checkbox v-model="pageForm.compulsory.checked" shape="square" icon-size="18px" @change="compulsoryCheck" checked-color="#ed6a0c" /></span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="compulsoryFormRef"
            fmCode="compulsory"
            :config="config.formLayout['compulsory']"
            :form="pageForm['compulsory']"
          >
          </HYForm>
        </div>
      </div>
      <!--商业险-->
      <div class="form quote-normal_commercial">
        <div class="form-title">
          <van-row>
            <van-col span="12">
              <span class="icon" style="background-color:#ff976a">商</span>
              <span>{{ config.formLayout['commercial'].fmName }}</span>
            </van-col>
            <van-col span="12">
              <span><van-checkbox v-model="pageForm.commercial.checked" shape="square" icon-size="18px" @change="commercialCheck" checked-color="#ed6a0c" /></span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="commercialFormRef"
            fmCode="commercial"
            :config="config.formLayout['commercial']"
            :form="pageForm['commercial']"
          >
          </HYForm>
        </div>
        <div v-if="config.riskLayout && pageForm.commercial.checked" :riskLayout="config.riskLayout">
          <RiskList ref="riskListRef" :layout="config.riskLayout" :riskForm="pageRiskForm" :pageForm="pageForm"/>
        </div>
      </div>
      <VehicleSelect ref="vehicleSelectRef" @selectVehicle="selectVehicle"/>
      <CheckCode ref="checkCodeRef" @checkCode="checkCode"/>
      <!-- <div class="quote-normal_bnt quote-normal_bnt_fixed">
        <div class="warn">
          <i class="van-icon van-icon-warning" /> 以上报价仅供参考，最终价格以出单当日为准
        </div>
        <div class="btn">
          <van-button :loading="pageData.submitLoading" loading-text="报价中" type="primary" block round @click="submit">确认报价</van-button>
        </div>
      </div> -->
  </div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import RiskList from "../components/RiskList.vue";
import VehicleSelect from '../components/VehicleSelect';
import CheckCode from '../components/CheckCode';
import layout from "../layout/index";
import { mapState } from "vuex";
import { keys } from "lodash";
import { request } from "@/api/service";
import mixinRisk from '../common/mixin/risk';
import mixinCommon from '../common/mixin/common';
import Common from "@/api/common/index";
import { Notify,Dialog } from "vant";
import dayjs from "dayjs";
import getCompanyData from '@/utils/setCompany'
import riskWatch from '@/utils/riskWatch'
export default {
  name: "QuoteRiskNormal",
  components: { HYForm,RiskList,VehicleSelect,CheckCode },
  mixins: [mixinRisk,mixinCommon,riskWatch],
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      pageData: {
        submitLoading:false
      },
      config: {
        scriptConfig: null,
        formLayout: null,
        riskLayout:null
      },
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote","quoteRes"]),
  },
  async created() {
    await this.initData();
    this.initLayout();
  },
  mounted() {
  },
  methods: {
    async initData() {
      await getCompanyData(this)
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayout() {
      const layoutData = layout("RISK");
      if (layoutData == null) {
        return null;
      }
      this.config.scriptConfig = layoutData.scriptConfig;
      const layoutAddData = layoutData.addConfig(this,{newEnergyFlag:this.quote.vehicle&&this.quote.vehicle.newEnergyFlag});
      this.initPageData(layoutAddData)
      this.initPageRiskData(layoutAddData.commercial.riskItems)
      this.config.formLayout = layoutAddData;
      this.config.riskLayout = layoutAddData.commercial.riskItems;
      //赋值
      Object.assign(this.pageForm,this.quote)
      this.pageForm.commercial && this.pageForm.commercial.riskList&&this.pageForm.commercial.riskList.forEach(riskItem=>{
        if(!this.pageRiskForm[riskItem.riskCode]){
          return false
        }
        riskItem.checked = true
        Object.assign(this.pageRiskForm[riskItem.riskCode],riskItem)
      })
      if (this.quote.commercial && this.quote.commercial.checked) {
        this.$set(this.pageForm.commercial,'checked',true)
      } else {
        this.pageForm.commercial = {}
        this.$set(this.pageForm.commercial,'checked',null)
      }
      if (this.quote.compulsory && this.quote.compulsory.checked) {
        this.$set(this.pageForm.compulsory,'checked',true)
      } else {
        this.pageForm.compulsory = {}
        this.$set(this.pageForm.compulsory,'checked',null)
      }
      if (!this.pageForm.compulsory.checked) {
        this.config.formLayout['compulsory'].formItems.map(item => {
          item.show = false
        })
      }
      if (!this.pageForm.commercial.checked) {
        this.config.formLayout['commercial'].formItems.map(item => {
          item.show = false
        })
      }
      return layoutData;
    },
    commercialCheck (val) {
      if (!val) {
        this.config.formLayout['commercial'].formItems.map(item => {
          item.show = false
        })
      } else {
        this.config.formLayout['commercial'].formItems[0].show = true
        this.config.formLayout['commercial'].formItems[1].show = true
        this.$set(this.pageForm.commercial, 'startTime', (new Date()).getTime() + 86400000 - (((new Date().getHours()) * 60 * 60 * 1000) + ((new Date().getMinutes()) * 60 * 1000) + ((new Date().getSeconds()) * 1000)))
        const endTimeData = dayjs(this.pageForm.commercial.startTime).add(1, "year");
        this.$set(this.pageForm.commercial, 'endTime', endTimeData.valueOf())
        this.$set(this.pageForm.commercial, 'insuredPlan', '1')
        this.$store.dispatch("car/setQuote", this.pageForm);
      }
    },
    compulsoryCheck (val) {
      if (!val) {
        this.config.formLayout['compulsory'].formItems.map(item => {
          item.show = false
        })
        this.$set(this.pageForm.compulsory,'checked', false)
      } else {
        this.config.formLayout['compulsory'].formItems[0].show = true
        this.config.formLayout['compulsory'].formItems[1].show = true
        this.config.formLayout['compulsory'].formItems[2].show = true
        this.$set(this.pageForm.compulsory,'checked', true)
        this.$set(this.pageForm.compulsory, 'startTime', (new Date()).getTime() + 86400000 - (((new Date().getHours()) * 60 * 60 * 1000) + ((new Date().getMinutes()) * 60 * 1000) + ((new Date().getSeconds()) * 1000)))
        const endTimeData = dayjs(this.pageForm.compulsory.startTime).add(1, "year");
        this.$set(this.pageForm.compulsory, 'endTime', endTimeData.valueOf())
      }
      this.$store.dispatch("car/setQuote", this.pageForm);
    },
    queryCompanyData () {
      let orgId = null
      if (this.quote.basis.orgIdVal) {
        let len = this.quote.basis.orgIdVal.length - 1
        if (len > 0 || len === 0) {
          orgId = this.quote.basis.orgIdVal[len]
        }
      }
      getCompanyData(this, orgId, this.quote.basis.dealerId)
    },
    queryIssuer () {
      let { basis } = this.quote
      let url = null
      let data = {}
      let orgId = null
      const { info } = this.$store.getters
      if (info) {
        if (info.busiType === '1') {
          let len = basis.orgIdVal.length
          orgId = basis.orgIdVal[len - 1]
        }
        if (info.busiType === '2') {
          orgId = basis.orgId
        }
      }
      if (basis.orgId && basis.dealerId) {
        url = '/organization/org/dealer/saleman'
        data = {
          orgId: orgId,
          dealerId: basis.dealerId
        }
      }
      if (basis.orgId && !basis.dealerId) {
        url = '/organization/emp/saleman/work'
        data = {
          orgId: orgId
        }
      }
      request({
        url: url,
        method: 'post',
        data: data
      }).then(res => {
        this.pageLayout.basis.issuerCode.config.options = (!res || res.code) ? [] : res
        const issuerCodeOptions = ((!res || res.code) ? [] : res).filter(item => item.id === this.pageForm.basis.issuerCode)
        if (issuerCodeOptions && !issuerCodeOptions.length) {
          this.pageForm.basis.issuerCode = null
        }
      })
    },
    getChannelData () {
      let len = this.quote.basis.orgIdVal.length
      request({
        url: '/organization/dealer/all',
        method: 'post',
        data: { orgId: this.quote.basis.orgIdVal[len - 1] }
      }).then(res => {
        this.pageLayout.basis.dealerId.config.options = res
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.quote-normal{
  background-color: $background-color;
  padding-top: 1px;
  &_bnt{
    margin-top: 10px;
    background-color: $white;
    .warn{
      height: 40px;
      line-height: 40px;
      font-size: $font-size-sm;
      color: $gray-6;
      padding: 0 10px;
      border-top:$border-color solid $border-width-base;
      border-bottom:$border-color solid $border-width-base;
    }
    .btn{
      padding: 10px 20px;
    }
    // &--fixed{
    //   position: fixed;
    //   bottom: 0;
    //   right: 0;
    //   left: 0;
    //   z-index: 99;
    //   background-color: $white;
    //   padding: 10px;
    //   border-top: $background-color solid $border-radius-sm;
    // }
  }
}
.quote-normal ::v-deep{
  .van-field__right-icon{
    color: $primary-color;
  }
}
.form {
  background-color: $white;
  margin-top: 6px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    border-bottom: 1px solid $border-color;
    span.icon {
      border-radius: $border-radius-sm;
      color: $white;
      font-size: $font-size-md;
      padding: 4px;
      margin:0 6px;
    }
    ::v-deep .van-checkbox{
      flex-direction: row-reverse;
      margin-right: 6px;
    }
  }
}
// .quote-normal_bnt_fixed {
//   position: fixed;
//   z-index: 10000;
//   bottom: 0;
//   width: 100%;
//   background: #fff;
// }

</style>
