<template>
  <div class="vehicle-model">
    <van-popup v-model="show" position="right" :style="{ width: '80%',height:'100%' }" >
      <van-tabs v-model="active">
        <van-tab title="厂牌型号">
          <div class="vehicle-model_search">
            <van-search
              v-model="brandConfig.form.brandModel"
              show-action
              placeholder="请输入厂牌型号"
              @search="onSearchBrand"
            >
              <template #action>
                <div @click="onSearchBrand">查询</div>
              </template>
            </van-search>
          </div>
          <div class="vehicle-model_list">
              <HYList ref="brandModelRef" v-bind="brandConfig" >
                <template #default="{ row }">
                  <van-cell :key="row.vehicleModelId" :title="handleCarInfo(row)" @click="handleClick(row)"/>
                </template>
                <template #empty>
                  <div class="none-tip">
                    <van-image :src="require('@/assets/images/none_data.jpg')"/>
                    <p>尊敬的用户，您当前暂无数据</p>
                  </div>
                </template>
              </HYList>
          </div>
        </van-tab>
        <van-tab title="车架号">
          <div class="vehicle-model_search">
            <van-search
              v-model="frameNoConfig.form.frameNo"
              show-action
              maxlength="17"
              placeholder="请输入车架号"
              @search="onSearchFrameNo"
            >
              <template #action>
                <div @click="onSearchFrameNo">查询</div>
              </template>
            </van-search>
          </div>
          <div class="vehicle-model_list">
              <HYList ref="frameNoRef" v-bind="frameNoConfig" >
                <template #default="{ row }">
                  <van-cell :key="row.vehicleModelId" :title="handleCarInfo(row)" @click="handleClick(row)"/>
                </template>
                <template #empty>
                  <div class="none-tip">
                    <van-image :src="require('@/assets/images/none_data.jpg')"/>
                    <p>尊敬的用户，您当前暂无数据</p>
                  </div>
                </template>
              </HYList>
          </div>
        </van-tab>
      </van-tabs>
    </van-popup>
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import { keys,cloneDeep } from 'lodash'
import { mapState } from "vuex";
export default {
  name: 'VehicleModel',
  inheritAttrs: false,
  components: {HYList},
  props: {

  },
  data () {
    return {
      show:false,
      active:0,
      brandConfig:{
        listKey:'vehicleModelList',
        initLoad:false,
        api:{
					url:'/afis-api-manage/invoke/core/auto/wxJYVehicleModelQueryIn'
				},
				form:{
					brandModel: null,
				},
        styleObj:{}
      },
      frameNoConfig:{
        listKey:'vehicleModelList',
        initLoad:false,
        api:{
					url:'/afis-api-manage/invoke/core/auto/wxJYVehicleModelQueryIn'
				},
				form:{
          frameNo: null,
				},
        styleObj:{}
      }
    }
  },
  computed: {
    ...mapState("car", ["quote"]),
  },
  created () {
    this.frameNoConfig.form.orgId = this.quote.basis.orgId
    this.frameNoConfig.form.dealerId = this.quote.basis.dealerId
    this.brandConfig.form.orgId = this.quote.basis.orgId
    this.brandConfig.form.dealerId = this.quote.basis.dealerId
  },
  methods: {
    open(data){
      if(data&&!this.frameNoConfig.form.frameNo){
        this.frameNoConfig.form.frameNo = data.frameNo
      }
      if(data&&!this.brandConfig.form.brandModel){
        this.brandConfig.form.brandModel = data.brandModel
      }
      this.show = true
      this.$nextTick(() => {
        this.onSearchBrand()
      })
    },
    onSearchBrand(){
      if(!this.brandConfig.form.brandModel  || this.brandConfig.form.brandModel.length<2){
        return false
      }
      this.$refs.brandModelRef.onRefresh()
    },
    onSearchFrameNo(){
      if(!this.frameNoConfig.form.frameNo){
        return false
      }
      this.$refs.frameNoRef.onRefresh()
    },
    handleCarInfo(car){
      const handleUnit = {
        purchasePrice:'元',
        seatCount:'座',
        yearPattern:'款'
      }
      let obj = {
        brandModel: car.brandModel,
        configName: car.configName,
        familyName: car.familyName,
        brandName: car.brandName,
        yearPattern: car.yearPattern,
        gearboxType: car.gearboxType,
        seatCount: car.seatCount,
        displacement: car.displacement,
        purchasePrice: car.purchasePrice
      }
      return keys(obj)
        .filter(fk =>car[fk] && fk !== 'vehicleModelId' && fk !== 'brandModelCode')
        .map(fk => `${car[fk]}${handleUnit[fk]||''}`)
        .join('/')
    },
    handleClick(row){
      const car = cloneDeep(row)
      const form = {
        brandModel : car.brandModel,
        brandName : car.brandName,
        configName : car.configName,
        displacement : car.displacement,
        emptyWeight : car.emptyWeight,
        familyName : car.familyName,
        purchasePrice : car.purchasePrice,
        vehicleModelId : car.vehicleModelId,
        yearPattern : car.yearPattern,
        gearboxType : car.gearboxType,
        brandModelCode : car.brandModelCode,
        seatCount : car.seatCount,
        modelDesc : this.handleCarInfo(car),
        origin : car.origin,
        platModelCode : car.platModelCode,
        platModelName : car.platModelName
      }
      if (car.fuelType) {
        form.fuelType = car.fuelType
      }
      this.show = false
      this.$emit('selectModel',form)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.vehicle-model{
  // height: 100vh;
  // overflow: hidden;
  &_search{
    // height: 50px;
  }
  &_list{
    // height: calc(100vh - 50px);
    // overflow: auto;
    ::v-deep .van-cell{
      padding:5px 15px;
    }
    ::v-deep .van-cell::after{
      display: flex;
    }
  }
}
.van-search__action{
  color:$primary-color
}
.vehicle-model ::v-deep{
  .van-tabs__line{
    background-color: $primary-color;
  }
  .van-tabs__content{
    margin-top: 0 !important;
  }
}

</style>
