<template>
  <div class="quote-normal-base h-font-lg">
    <van-tabs v-model="currentIndex" ref="vanTab" type="card" sticky scrollspy :ellipsis="false" style="padding-bottom: 60px;">
      <van-tab v-for="(formItem, index) in config.formLayout" :key="index" :name="formItem.fmCode" :title="formItem.fmName">
        <div class="quote-normal-base_content">
          <HYForm
              :key="index"
              :ref="formItem.fmCode + 'FormRef'"
              :fmCode="formItem.fmCode"
              :config="formItem"
              :form="pageForm[formItem.fmCode]">
              <template  #vehicle_plateNo="{ form, config }">
                <HInput v-model="form.plateNo" :config="config">
                  <template #extra>
                    <div class="vehicle-plateNo h-flex">
                      <van-checkbox v-model="form.plateFlag" iconSize="16px" checkedColor="#57ccc2" shape="square">无</van-checkbox>
                      <!-- <van-uploader :after-read="ocrVehicle" max-count="1"><van-icon name="scan" size="20"/></van-uploader> -->
                      <van-icon name="scan" size="20" @click="plateOcrCli"/>
                    </div>
                  </template>
                </HInput>
              </template>
              <!-- <template #vehicle_frameNo="{ config }">
                <HInput :config="config">
                  <template #extra>
                    <div class="vehicle-frameNo h-flex-col">
                      <van-button plain type="primary" size="mini" @click="openVehicleModel">车型查询</van-button>
                    </div>
                  </template>
                </HInput>
              </template> -->
              <template #vehicle_modelDesc="{ config }">
                <HField :config="config" @click.native="openVehicleModel">
                  <template #extra>
                    <div class="vehicle-modelDesc">
                      <i class="van-icon van-icon-arrow van-cell__right-icon" @click="openVehicleModel"></i>
                    </div>
                  </template>
                </HField>
              </template>
              <template #holderHeader="{ form }">
                  <div class="form-header h-flex h-row-between">
                    <van-checkbox v-model="form.sameOwnerFlag" shape="square" checkedColor="#57ccc2" iconSize="16px">同车主</van-checkbox>
                    <!-- <van-uploader v-if="!form.sameOwnerFlag" :after-read="ocrHolder" max-count="1"><van-icon name="scan" size="20"/></van-uploader> -->
                    <van-icon name="scan" size="20" @click="cardOcrCli('holder')"/>
                  </div>
              </template>
              <template #insuredHeader="{ form }">
                  <div class="form-header h-flex h-row-between">
                    <div class="h-flex h-row-between">
                      <van-checkbox v-model="form.sameHolderFlag" shape="square" checkedColor="#57ccc2" iconSize="16px">同投保人</van-checkbox>
                      &nbsp;&nbsp;
                      <van-checkbox v-model="form.sameOwnerFlag" shape="square" checkedColor="#57ccc2" iconSize="16px">同车主</van-checkbox>
                    </div>
                    <!-- <van-uploader v-if="!(form.sameHolderFlag||form.sameOwnerFlag)" :after-read="ocrInsured" max-count="1"><van-icon name="scan" size="20"/></van-uploader> -->
                    <van-icon name="scan" size="20" @click="cardOcrCli('insured')"/>
                  </div>
              </template>
          </HYForm>
        </div>
      </van-tab>
      <van-tab  key="risk" name="risk" title="险种信息">
        <Risk key="risk" ref="riskFormRef" />
      </van-tab>
    </van-tabs>
    <VehicleModel ref="vehicleModelRef" @selectModel="selectModel" />
    <div class="quote-normal-base_bnt">
      <van-row class="quote-normal-base_bnt--fixed" type="flex" justify="center">
        <div class="warn">
          <i class="van-icon van-icon-warning" /> 以上报价仅供参考，最终价格以出单当日为准
        </div>
        <div class="btn">
          <van-button :loading="pageData.submitLoading" loading-text="报价中" type="primary" block round @click="submit">立即报价</van-button>
        </div>
      </van-row>
    </div>
    <CardOcr ref="holderOcr" :type="'holder'"/>
    <CardOcr ref="insuredOcr" :type="'insured'"/>
    <PlateOcr ref="plateOcr" />
    <CheckCode ref="checkCodeRef" @checkCode="checkCode"/>
    <VehicleSelect ref="vehicleSelectRef" @selectVehicle="selectVehicle"/>
</div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import HInput from '@/components/form-items/HInput'
import HField from '@/components/form-items/HField'
import layout from "../layout/index";
import mixinBase from '../common/mixin/base';
import mixinCommon from '../common/mixin/common';
import { mapState } from "vuex";
import VehicleModel from '../components/VehicleModel';
import { Toast, Notify } from "vant";
import { mergeWith, isNaN } from "lodash";
import Risk from './risk.vue'
import CardOcr from '@/components/cardOcr/index'
import PlateOcr from '@/components/plateOcr/index'
import CheckCode from '../components/CheckCode';
import VehicleSelect from '../components/VehicleSelect';
import riskWatch from '@/utils/riskWatch'
import MotorRisk from '@/views/miniprogram/quote/layout/risk/motor.js'
import EnergyRisk from '@/views/miniprogram/quote/layout/risk/energy.js'
export default {
  name: "QuoteBaseNormal",
  components: { HYForm,HInput,HField,VehicleModel,Risk,CardOcr,PlateOcr,CheckCode,VehicleSelect },
  mixins: [mixinBase,mixinCommon,riskWatch],
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      pageData: {
        submitLoading: false
      },
      currentIndex: 'vehicle',
      codeList:['vehicle','owner','holder','insured'],
      config: {
        scriptConfig: null,
        formLayout: null,
      },
      validateFlag: false
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote"])
  },
  async created() {
    await this.initData();
    this.initLayoutBase();
  },
  mounted() {
    setTimeout(() => {
      this.currentIndex = 'vehicle'
    })
  },
  methods: {
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayoutBase() {
      const layoutData = layout("BASE");
      if (layoutData == null) {
        return null;
      }
      this.config.scriptConfig = layoutData.scriptConfig;
      const layoutAddData = layoutData.addConfig(this,this.pageForm,this.pageLayout);
      this.initPageData(layoutAddData)
     
      this.config.formLayout = layoutAddData;
      this.$set(this.pageForm.holder,'sameOwnerFlag',false)
      this.$set(this.pageForm.insured,'sameHolderFlag',false)
      this.$set(this.pageForm.insured,'sameOwnerFlag',false)
      this.$set(this.pageForm.vehicle,'plateFlag',null)
      Object.assign(this.pageForm,this.quote)
      this.pageForm.vehicle.carryingWeight=''+this.quote.vehicle.carryingWeight
      return layoutData;
    },
    handlerDisabled(){
      Toast("请点击下一步按钮");
    },
    async submit() {
      // 获取商业险交强险信息
      if (this.$refs.riskFormRef.pageForm.commercial && this.$refs.riskFormRef.pageForm.commercial.checked) {
        this.pageForm.commercial = this.$refs.riskFormRef.pageForm.commercial
      } else {
        this.pageForm.commercial = {}
      }
      if (this.$refs.riskFormRef.pageForm.compulsory && this.$refs.riskFormRef.pageForm.compulsory.checked) {
        this.pageForm.compulsory = this.$refs.riskFormRef.pageForm.compulsory
      } else {
        this.pageForm.compulsory = {}
      }
      if (this.$refs.riskFormRef.pageForm.basis) {
        const basis=this.$refs.riskFormRef.pageForm.basis || {}
        this.pageForm.basis.insuredOrgCode = basis.insuredOrgCode
      } 
      //基本信息验证。
      for (let i = 0; i < this.codeList.length; i++) {
        const formRef=this.$refs[this.codeList[i]+'FormRef']&&this.$refs[this.codeList[i]+'FormRef'][0]
        this.validateFlag = await formRef.validateForm();
        if (!this.validateFlag) {
          this.currentIndex = this.codeList[i]
          this.$refs.vanTab.scrollTo(this.codeList[i])
          break
        }
      }
      if (!this.validateFlag) {
        Notify({ type: "warning", message: "请完善信息" });
        return
      }
      const basisFormRef = this.$refs.riskFormRef && this.$refs.riskFormRef.$refs.basisFormRef
      const validateBasis = await basisFormRef.validateForm()
      if(!validateBasis){
        Notify({ type: "warning", message: "请完善基本信息" });
        this.currentIndex = 'risk'
        this.$refs.vanTab.scrollTo('risk')
        return false
      }
      if(!(this.pageForm.compulsory.checked || this.pageForm.commercial.checked)){
        Notify({ type: "warning", message: "交强险和商业险至少选择一种" });
        this.currentIndex = 'risk'
        this.$refs.vanTab.scrollTo('risk')
        return false
      }
      if(this.pageForm.compulsory.checked){ //交强险验证
        const compulsoryFormRef = this.$refs.riskFormRef && this.$refs.riskFormRef.$refs.compulsoryFormRef
        const validateCompulsory = await compulsoryFormRef.validateForm()
        if(!validateCompulsory){
          Notify({ type: "warning", message: "请完善交强险信息" });
          this.currentIndex = 'risk'
          this.$refs.vanTab.scrollTo('risk')
          return false
        }
      }
      if(this.pageForm.commercial.checked){ //商业险
        const commercialFormRef = this.$refs.riskFormRef && this.$refs.riskFormRef.$refs.commercialFormRef
        const validateCommercial = await commercialFormRef.validateForm()
        if(!validateCommercial){
          Notify({ type: "warning", message: "请完善商业险基本信息" });
          this.currentIndex = 'risk'
          this.$refs.vanTab.scrollTo('risk')
          return false
        }
        const riskListRef = this.$refs.riskFormRef && this.$refs.riskFormRef.$refs.riskListRef
        const errorList = await riskListRef.validate();
        if(errorList&&errorList.length>0){
          Notify({ type: "warning", message: "请完善商业险险种信息" });
          this.currentIndex = 'risk'
          this.$refs.vanTab.scrollTo('risk')
          return false
        }
        const riskForm = riskListRef.riskForm
        const riskList=[]
        for(const code in riskForm){
          const form=riskForm[code]
          if(form&&form.riskCheck){
            riskList.push(form)
          }
        }
        let motorRiskList = MotorRisk().map(item => item.riskCode)
        let energyRiskList = EnergyRisk().map(item => item.riskCode)
        let riskNum = 0
        let rsikListParam = []
        riskList.map(item => {
            if (this.pageForm.vehicle.newEnergyFlag === '0' && motorRiskList.includes(item.riskCode)) {
                riskNum++
                rsikListParam.push(item)
            }
            if (this.pageForm.vehicle.newEnergyFlag === '1' && energyRiskList.includes(item.riskCode)) {
                riskNum++
                rsikListParam.push(item)
            }
        })
        if(rsikListParam.length<1&&!riskNum){
          Notify({ type: "warning", message: "商业险至少投保一个险种" });
          this.currentIndex = 'risk'
          this.$refs.vanTab.scrollTo('risk')
          return  false
        }
        this.pageForm.commercial.riskList=rsikListParam
      }
      await this.$store.dispatch("car/setQuote", this.pageForm);//保存缓存
      this.vehicleQuoteIn()
    },
    cardOcrCli (type) {
      this.$refs[type+'Ocr'].cardShow = true
    },
    disabledTab(index){
      return index > this.currentIndex+1
    },
    plateOcrCli () {
        this.$refs.plateOcr.cardShow = true
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.quote-normal-base{
  background-color: $background-color;
  height: 100vh;
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
    ::v-deep .van-button{
      width: 100px;
    }
    .warn{
      height: 40px;
      line-height: 40px;
      font-size: $font-size-sm;
      color: $gray-6;
      padding: 0 10px;
      border-top:$border-color solid $border-width-base;
      border-bottom:$border-color solid $border-width-base;
    }
    .btn{
      padding: 10px 20px;
      width: 100%;
      .van-button {
        width: 100%;
      }
    }
  }
}
.quote-normal-base ::v-deep{
  .van-sticky{
    padding: 5px 0;
    background-color:$white;
    .van-tabs__nav{
      padding: 5px;
      height: 40px;
      border-color:$primary-color;
    }
    .van-tab{
      padding: 10px 0;
      color:$primary-color;
      border:none;
      border-radius:$border-radius-sm;
    }
    .van-tab--active{
      background-color:$primary-color;
      color:$white;
    }
  }
  .van-tabs__content{
    margin-top:10px;
  }
  .van-field__right-icon{
    color: $primary-color;
  }
  .vehicle-plateNo{
    padding: 0 10px;
    .van-icon-scan{
      padding-left:15px;
    }
  }
  .vehicle-frameNo{
    padding: 0 10px;
  }
  .form-header{
    background-color: $white;
    padding: 15px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-sm, $padding-sm);
    }
  }
}
</style>
